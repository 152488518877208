<template>
  <div class="rlzy">
    <div class="header">
      <div class="h1">人力资源</div>
      <div class="h2">人才计划 助力企业经济腾飞</div>
    </div>
    <div class="content">
        <el-row >
          <el-col  :span="4"  v-for="(li,index) in ul" :key="index">
            <div class="tu1" @click="changeJob(li)">
              <div><el-image :size="140" :src="li.image"></el-image></div>
              <div>{{li.name}}</div>
            </div>
          </el-col>
        </el-row>
      <!-- <el-row type="flex">
        <el-col    v-for="(li,index) in biaoqian">
          <div class="biaoqian">
            <el-tag type="info" style="padding: 0 44px">{{li.name}}</el-tag>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <div class="cent">
      <div class="title">热门招聘职位</div>
      <div class="listall">
        <div class="lists" v-for="(item,index) in arr" @click="goxq(item)" :key="index">
          <div class="h1 text-overflow">
            <div><span class="fontweight ">{{item.title}}</span><span class="money">{{item.benefitsType==2?item.minSalary+'-'+item.maxSalary:'面议'}}</span></div>
            <div class="tags">
              <el-tag size="small" style="margin-right: 5px;margin-top: 5px"  v-for="(li,index) in item.welfareNames.split(',')" type="info" :key="index">{{li}}</el-tag>
            </div>
          </div>
          <div class="flex company">
            <div class="flex-1 ">{{item.jobYears}}</div>
            <div style="width: 120px;text-align: right">{{item.categoryName}}    I    {{item.recruitMethod}}</div>
          </div>
        </div>
      </div>
      <div class="cent" style="text-align: center;padding: 30px 0">
        <el-button class="btn" type="warning" @click="goto">查看更多岗位</el-button>
      </div>
    </div>
    <div class="bg2">
      <div class="zhuti">
        <div class="title">更好实现人才输送
          <div class="h3">平台搭建起各行企业与求职者之间的杯梁，实现信息的有效传递和</br>人才的合理配置。同时，该平台还可以提供更多的人才培养和职业发展服务帮助企业和求职者实现更好的人才输送和管理。</div>
          <div><el-button type="primary" size="small" @click="goto">去看工作机会</el-button></div>
        </div>
      </div>
    </div>
    <div style="background: linear-gradient(180deg, #F0F5FE 0%, #FEFEFF 100%);padding: 56px 0;">
      <div class="zhuti flex">
        <div class="rencai">政府推荐人才库
          <div class="h2">政府建立人才信息数据库、制定人才推荐标准、加强人才评价和评估、搭建有利于高精尖人才发展和施展才华的平台</div>
          <div><el-button @click="gotolist" type="primary">查看更多</el-button></div>
        </div>
        <div class="tubiao" ref="main">
        </div>
      </div>
    </div>
    <div class="bg1">
      <div class="zhuti">
        <div class="title">免费人力资源公开课
          <div class="h2">每个视频攻破一个实用知识点/每天进步一点点</div>
          <div class="h2">通过免费人力资源课程的学习，求职者可以更好地了解职业发展的趋势和机会，掌握职场必备技能和知识，为个人职业发展打下坚实的基础</div>
        </div>
        <div class="news">
          <div class="newlist" v-for="(new1,index) in news"   @click="gotoxq(new1)" :key="index">
            <div class="img"> <el-image :src="new1.cover"></el-image></div>
            <div class="desc">
              <div class="title1 text-overflow">{{new1.title}}</div>
              <div class="title2"><i class="el-icon-view"></i><span>播放量：{{new1.readCount}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {humanindex} from "../../request/moudle/rlzy";

export default {
  name: "index",
  data(){
    return {
      news:[],
      datas:'',
      ul:[],
      biaoqian:[],
      arr:[],
    }
  },
  mounted() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.rlzy.humanindex().then((res)=>{
        this.datas=res.data
        this.news=res.data.courseList
        this.arr=res.data.hotRecruitList
        this.ul=res.data.topCategoryList
        this.biaoqian=res.data.otherCategoryList
        this.init()
      })
    },
    changeJob(item){
      console.log(item,'item')
      this.$router.push({
        path:'/rlzylist',
        query:{
          id:item.id
        }
      })
    },
    goxq(item){
      this.$router.push({
        path:'/rlzyfind',
        query:{
          id:item.id
        }
      })
    },
    gotoxq(item){
      this.$router.push({
        path:'/zxpxxq',
        query:{
          id:item.id
        }
      })
    },
    goto(){
      this.$router.push({
        path:'/rlzylist',
      })
    },
    gotolist(){
      this.$router.push({
        path:'/rlzylist',
        query:{
          type:1
        }
      })
    },
    init(){
      let options={
        xAxis: {
          type: 'category',
          data: this.datas.talentsCount.x
        },
        yAxis: {
          type: 'value',
          name:'(人)',
          nameTextStyle: {
            padding: [15, 20, 15, 0], //间距分别是 上 右 下 左
          },
        },
        series: [
          {
            data: this.datas.talentsCount.y,
            type: 'bar',
            barMaxWidth:'50'
          },
        ]
      }
      this.$nextTick(()=>{
        const  myCharts = this.$echarts.init(this.$refs.main);
        myCharts.setOption(options);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rlzy{
  background: #fff;
  .header{
    height: 220px;
    background: url("../../assets/banner/rlzy.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    text-align: center;
    color: #fff;
    .h1{
      font-size: 42px;
      font-weight: 500;
    }
    .h2{
      font-size: 22px;
      line-height: 80px;
    }
  }
  .bg1{
    background: url("../../assets/rlzy/img.png");
    background-size: 100% 100%;
    padding: 56px 0;
  }
  .bg2{
    color: #fff;
    padding: 56px 0;
    background: linear-gradient(85deg, rgba(55, 63, 90, 0.94) 3.14%, rgba(55, 63, 90, 0.90) 100%);
  }
  .zhuti{
    width: 1200px;
    margin: 0 auto;
  }
  .rencai{
    width: 580px;
    font-size: 22px;
    font-weight: bolder;
    margin-top: 88px;
    .h2{
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 35px;
      color: #999;
      font-weight: normal;
    }
  }
  .tubiao{
    width: 642px;
    height: 354px;
    margin: 0 auto;
  }
  .gaoxiao{
    width: 100%;
    height: 98px;
    margin: 40px 0;
  }
  .title{
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
    padding: 10px 0;
    .h2{
      font-weight: 500;
      font-size: 14px;
      line-height: 35px;
    }
    .h3{
      font-weight: 500;
      font-size: 14px;
      margin: 35px 0;
    }
  }
  .news{
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 100px;
    overflow: hidden;
    margin-top: 35px;
    .newlist{
      width: 280px;
      float: left;
      position: relative;
      margin: 8px;
      cursor: pointer;
      .img{
        height: 100%;
        background: #F1F3F3;
      }
    }
  }
  .el-icon-view{
    margin-right: 8px;
  }
  .desc{
    color: #1D1D1D;
    padding: 10px;
    .title1{
      font-weight: bolder;
      margin: 8px 0;
    }
    .title2{
      color: #999;
      font-size: 12px;
    }
  }
  .listall{
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
  }
  .lists{
    width: 340px;
    float: left;
    background: #fff;
    position: relative;
    margin: 15px 15px;
    border-radius: 4px;
    cursor: pointer;
    .h1{
      margin: 24px;
    }
    .fontweight{
      font-weight: bolder;
    }
    .money{
      color: red;
      float: right;
    }
    .tags{
      margin-top: 10px;
    }
    .company{
      height: 45px;
      background: #DBE8F0 ;
      color: #4F4F4F;
      line-height: 45px;
      padding: 0 20px;
      font-size: 12px;
    }
  }
  .content{
    width: 1200px;
    margin: 31px auto 31px auto;
    background: #fff;
    .tu1{
      width: 140px;
      text-align: center;
    }
    .biaoqian{
      margin-top: 35px;
    }
  }
  .cent{
    background: #f5f5f5;
  }
}

</style>